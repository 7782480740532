import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as defaultLanguage from '@assets/i18n/no.json';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { LayoutComponent } from '@components/layout/layout.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [LayoutComponent]
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    translate.setTranslation('no', defaultLanguage);
    translate.setDefaultLang('no');

    // Set favicon based on environment
    const favicon = this.document.querySelector('link[rel="icon"]');
    favicon?.setAttribute('href', environment.faviconPath);
  }
}
