@if (!isHidden() && checkAnyOptionHasRole([currentRole()])) {
<h3 class="w-full text-left">
  {{ menuTitle() | translate }}
</h3>
}

<mat-tree #tree [dataSource]="filteredMenuOptions()" [childrenAccessor]="childrenAccessor" class="menu-tree grid">
  <!-- Expandable node -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChild" matTreeNodeToggle>
    <button mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="node neutral">
      @if (node.icon) { <mat-icon class="align-middle scale-125 opacity-75">{{ node.icon }}</mat-icon> }
      <span>{{ node.name }}</span>
      <mat-icon iconPositionEnd [ngClass]="{'rotate-180': tree.isExpanded(node)}"
        class="ml-auto scale-125 transition-height">
        expand_more
      </mat-icon>
    </button>
    <ul [@slideVertical]="tree.isExpanded(node) ? 'show' : null" class="overflow-clip grid" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </ul>
  </mat-nested-tree-node>
  <!-- Leaf node -->
  <mat-nested-tree-node *matTreeNodeDef="let node">
    <a mat-button routerLink={{node.redirectUrl}} (click)="closeMenuEventEmit(); $event.stopPropagation()"
      class="node neutral"
      [ngClass]="{'bg-yellow-deep/50 dark:bg-black/30': isCurrentActiveMenuItem(node.redirectUrl)}">
      @if (node.icon) { <mat-icon class="align-middle scale-125 opacity-75">{{ node.icon }}</mat-icon> }
      @if (node.redirectUrl === '/deviations') {
      <span [matBadge]="settlementBadgeCount()" matBadgeOverlap="false" [matBadgeHidden]="!settlementBadgeCount()">
        {{ node.name }}
      </span>
      }
      @else if (node.redirectUrl === '/washDeviation') {
      <span [matBadge]="washSettlementBadgeCount()" matBadgeOverlap="false"
        [matBadgeHidden]="!washSettlementBadgeCount()">
        {{ node.name }}
      </span>
      }
      @else {
      <span>{{ node.name }}</span>
      }
    </a>
  </mat-nested-tree-node>
</mat-tree>