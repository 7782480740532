import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PaginatedList } from '@app/models/common/paginated-list.class';
import { WashSale } from '@app/models/washsettlement/wash-sale.model';
import { WashDeviations } from '@app/models/washsettlement/wash-deviation.model';

@Injectable()
export class WashSettlementService {

  private uri: string = `${environment.routes.apiEndpoint}/wash-settlement`;

  constructor(private http: HttpClient) { }

  getSales(
    customerIds: string[] = [],
    completedAtDateFrom: Date | null = null,
    completedAtDateTo: Date | null = null,
    sortByField: string = 'SourceDate',
    sortDecending: boolean = true,
    pageIndex: number = 1,
    pageSize: number = 100) {
    let filters = `pageSize=${pageSize}&pageIndex=${pageIndex}&sortByField=${sortByField}&sortDecending=${sortDecending}`;
    if (customerIds.length) filters += `&customerIds=${customerIds.join('&customerIds=')}`;
    if (completedAtDateFrom) filters += `&completedAtDateFrom=${completedAtDateFrom.toISOString()}`;
    if (completedAtDateTo) filters += `&completedAtDateTo=${completedAtDateTo.toISOString()}`;
    return this.http.get<PaginatedList<WashSale>>(`${this.uri}/sales?${filters}`);
  }

  getDeviations(dateFrom: Date | null = null, dateTo: Date | null = null) {
    let filters = '';
    if (dateFrom) filters += `dateFrom=${dateFrom.toISOString()}`;
    if (dateTo) filters += `&dateTo=${dateTo.toISOString()}`;
    return this.http.get<WashDeviations>(`${this.uri}/sales/deviations?${filters}`);
  }

  postRunExport() {
    return this.http.post(`${this.uri}/export/run`, null);
  }
}
