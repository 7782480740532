<mat-sidenav-container class="h-full" autosize>
  <mat-sidenav #sidenav="matSidenav" class="p-4 text-center bg-yellow dark:bg-monochrome-darker">
    <a routerLink="/" class="flex mt-2 justify-center items-center" (click)="closeIfMobile()">
      <img alt="{{ 'menu.unox-logo' | translate }}" class="dark:hidden h-20 fill-current"
        src="assets/logo/unox_logo.svg" />
      <img alt="{{ 'menu.unox-logo' | translate }}" class="hidden dark:block h-20 fill-current"
        src="assets/logo/unox_logo_darkmode_alt1.svg" />
    </a>
    @if (environment.name.toLowerCase() !== 'prod') {
    <p class="text-2xl font-bold text-red dark:text-yellow tracking-wider mt-4">{{ environment.name }}</p>
    }
    <!-- Sidebar menu -->
    <div class="flex flex-col gap-4">
      <!-- Global options -->
      <app-layout-menu-section [menuTitle]="''" [menuOptions]="globalMenuOptions" [isHidden]="true"
        [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

      <!--Logistics options-->
      <app-layout-menu-section [menuTitle]="'menu.logistics'" [menuOptions]="logisticsMenuOptions"
        [isHidden]="!isLoggedIn" [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

      <!--Operations options-->
      <app-layout-menu-section [menuTitle]="'menu.operations'" [menuOptions]="operationsMenuOptions"
        [isHidden]="!isLoggedIn" [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

      <!--Settlement options-->
      <app-layout-menu-section [menuTitle]="'menu.stationSettlement'" [menuOptions]="settlementMenuOptions"
        [isHidden]="!isLoggedIn" [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

      <!--Wash settlement options-->
      <app-layout-menu-section [menuTitle]="'menu.washSettlement'" [menuOptions]="washSettlementMenuOptions"
        [isHidden]="!isLoggedIn" [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

      <!--Price options-->
      <app-layout-menu-section [menuTitle]="'menu.price'" [menuOptions]="priceMenuOptions" [isHidden]="!isLoggedIn"
        [currentRole]="currentRole" (closeMenuEvent)="closeIfMobile()">
      </app-layout-menu-section>

    </div>

    <!-- Contact info when not logged in -->
    @if (!isLoggedIn) {
    <div class="absolute inset-x-0 bottom-4 opacity-90 dark:opacity-100 dark:text-monochrome-lighter">
      <address class="not-italic">
        <span class="font-medium">Uno-X Mobility Norge AS</span><br>
        Org. nr: 921757131<br>
        Gladengveien 2<br>
        0661 Oslo<br>
        <a href="tel:+4722124212">Tlf: 22 12 42 12</a><br>
        <a href="mailto:bedrift@unox.no"> {{ 'bedrift@unox.no' }}</a>
      </address>
    </div>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar
      class="bg-yellow dark:bg-monochrome-darker md:bg-white dark:md:bg-monochrome-darkest sticky top-0 z-50 max-sm:shadow">
      <div class="flex justify-between w-full">
        <!-- Sidebar button -->
        <div class="flex items-center">
          <button class="text-monochrome-dark dark:text-monochrome-lightest" mat-icon-button (click)="sidenav.toggle()"
            attr.aria-label="{{ 'menu.toggleSidebar' | translate }}">
            <mat-icon class="text-right">{{sidenav.opened ? 'menu_open' : 'menu'}}</mat-icon>
          </button>
        </div>
        <!-- End justified content -->
        <div class="flex justify-end space-x-4">
          <!-- Dark/light button -->
          <button mat-icon-button class="max-md:hidden flex justify-center items-center rounded-lg"
            (click)="toggleDarkMode()">
            <mat-icon class="scale-125 md:text-monochrome-dark md:dark:text-monochrome-lighter">
              @if (localStorage.theme === 'dark') {
              <span>light_mode</span>
              }
              @if (localStorage.theme !== 'dark') {
              <span>dark_mode</span>
              }
            </mat-icon>
          </button>
          <!-- Login button -->
          @if (!isLoggedIn) {
          <button mat-button class="rounded-lg my-auto" (click)="login()">
            <mat-icon class="scale-125 dark:md:text-current">login</mat-icon>
            <span class="ml-2">{{ 'ui.login' | translate }}</span>
          </button>
          }
          <!-- Menu button -->
          @if (isLoggedIn) {
          <button mat-button [matMenuTriggerFor]="menu" #t="matMenuTrigger" class="rounded-lg h-fit my-auto">
            <div class="flex items-center justify-end space-x-2">
              <div class="font-clan tracking-wide flex flex-col content-center justify-end text-right">
                <p class="text-base font-medium my-auto text-monochrome-dark dark:text-monochrome-lightest">
                  {{ usersName ?? 'ui.notLoggedIn' | translate }}
                </p>
                <p
                  class="text-sm dark:text-monochrome-light dark:md:text-monochrome-gray inline-block truncate max-sm:w-40">
                  {{ 'roles.' + currentRole | translate }}
                </p>
              </div>
              <mat-icon class="md:mx-2 text-monochrome-dark dark:text-monochrome-lighter primary-badge"
                aria-hidden="false" [@toggleMenuOpen]="t.menuOpen ? 'open' : 'closed'" [matBadge]="getChangelogBadge()"
                matBadgeDescription="{{'ui.unreadChangelogs' | translate: { count: getChangelogBadge()} }}"
                [matBadgeHidden]="!getChangelogBadge() || t.menuOpen">
                expand_more
              </mat-icon>
            </div>
          </button>
          }
          <!-- Menu dropdown content -->
          <mat-menu #menu="matMenu" xPosition="before">
            <!-- Changelog -->
            @if (getDropdownMenuItem('/changelog'); as menuOption) {
            <button mat-menu-item>
              <a [routerLink]="menuOption.redirectUrl" class="flex items-center">
                <mat-icon>{{ menuOption.icon }}</mat-icon>
                <span class="primary-badge" [matBadge]="getChangelogBadge()"
                  matBadgeDescription="{{'ui.unreadChangelogs' | translate: { count: getChangelogBadge()} }}"
                  [matBadgeHidden]="!getChangelogBadge()">
                  {{ menuOption.name }}
                </span>
              </a>
            </button>
            }
            <!-- Logout -->
            @if (getDropdownMenuItem('/logout'); as menuOption) {
            <button mat-menu-item
              class="md:hidden flex justify-center items-center px-4 py-2.5 rounded-lg transition duration-300"
              (click)="$event.stopPropagation(); toggleDarkMode()">
              <mat-icon class="md:text-current">
                @if (localStorage.theme === 'dark') {
                <span>light_mode</span>
                }
                @if (localStorage.theme !== 'dark') {
                <span>dark_mode</span>
                }
              </mat-icon>
              <span>{{ 'ui.mode.' + (localStorage.theme === 'dark' ? 'light' : 'dark') | translate }}</span>
            </button>
            @if (getDropdownMenuItem('/my-page'); as myPage) {
            <a mat-menu-item [routerLink]="myPage.redirectUrl">
              <mat-icon>{{ myPage.icon }}</mat-icon>
              <span>{{ myPage.name }}</span>
            </a>
            }
            <button mat-menu-item (click)="logOut()">
              @if (menuOption.icon) {<mat-icon>{{ menuOption.icon }}</mat-icon>}
              <span>{{ menuOption.name }}</span>
            </button>
            }
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
    <!-- Main content of website -->
    <main class="content lg:w-5/6 mx-auto px-4 md:px-10 mt-4">
      @if (!isIframe) {
      <router-outlet></router-outlet>
      }
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>