import { PaginatedList } from './../../models/common/paginated-list.class';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlarmConfiguration } from '@app/models/alarms/alarmConfigs.class';
import { AlarmHistory, AlarmStatus } from '@app/models/alarms/alarmHistory.class';
import { StationAlarms, ExpandedStationComment } from "@app/models/alarms/alarms.class";
import { PosEvent } from '@app/models/common/posEvent.class';
import { environment } from "@environments/environment";

@Injectable()
export class AlarmService {
  private uri: string = `${environment.routes.apiEndpoint}/alarm`;

  constructor(private http: HttpClient) { }

  public getAlarms(stationIds: string[] = [], stationGroupIds: string[] = [],
    pageSize = 1000, pageIndex = 0) {
    let filters = '';
    filters += `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    return this.http.get<PaginatedList<StationAlarms>>(`${this.uri}/alarms/monitored?${filters}`);
  }

  public getAlarmHistories(
    stationId: string, 
    alarmTypes: number[] = [], 
    statuses: number[] = [], 
    sortField: string,
    sortDescending: boolean,
    pageSize = 1000,
    pageIndex = 0){
      
    
    let filters = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortField}&SortDescending=${sortDescending}&StationId=${stationId}`;
    if (alarmTypes.length) filters += `&AlarmTypes=${alarmTypes.join('&AlarmTypes=')}`;
    if (statuses.length) statuses.map(x => filters += `&Statuses=${x}`)
      
    return this.http.get<PaginatedList<AlarmHistory>>(`${this.uri}/alarms/history?${filters}`);
  }

  public getAllStatusCodes(){
    return this.http.get<AlarmStatus[]>(`${this.uri}/alarms/statuses?`);
  }

  getComments(stationIds: string[],
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string | null = null, sortDecending = true) {

    let filters = '';
    filters += `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (sortField) filters += `&SortField=${sortField}&SortDescending=${sortDecending}`;

    return this.http.get<PaginatedList<ExpandedStationComment>>(`${this.uri}/stationComments?${filters}`);
  }

  saveComment(stationId: string, comment: string) {
    return this.http.post(`${this.uri}/stationComments`,
      { comment: comment, stationId: stationId });
  }

  public getAlarmConfig(
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'created', sortDescending: boolean = true,
    isActiveFilter?: boolean
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (sortField) filters += `&SortByField=${sortField}`;
    if (sortDescending) filters += `&SortDescending=${sortDescending}`;
    if (isActiveFilter) filters += `&IsActive=${isActiveFilter}`;
    return this.http.get<PaginatedList<AlarmConfiguration>>(`${this.uri}/alarmConfigurations?${filters}`);
  }

  public saveAlarmConfig(alarmConfig: AlarmConfiguration) {
    return this.http.post(`${this.uri}/alarmConfigurations`, alarmConfig);
  }

  public resetDeviceAlarms(stationId: string, deviceId: string) {
    return this.http.post(`${this.uri}/alarms/cleanup`, { stationId: stationId, deviceId: deviceId });
  }

  public getPosEventArchive(stationId: string, selectedDate: Date | null){
    if(stationId === null)
      return; 

    if(selectedDate === null)
      selectedDate = new Date();

    const filters = `StationId=${stationId}&SelectedDate=${selectedDate.toISOString()}`

    return this.http.get<PaginatedList<PosEvent>>(`${this.uri}/archive/posEvents?${filters}`);
  }
}
