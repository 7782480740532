import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { setUserInformation } from '@store/currentUser/currentUser.actions';
import { AuthorisationService } from '../http/authorisation.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
  private readonly _destroying$ = new Subject<void>();
  private uri: string = `${environment.routes.apiEndpoint}/authorisation/roles`;

  constructor(
    private authService: AuthService,
    private authorisationService: AuthorisationService,
    private store: Store
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.isUserLoggedIn$.next(true);
        this.getUserInfo();
      }
      else {
        this.isUserLoggedIn$.next(false);
        this.login();
      }
    });
  }

  /**
   * Creates user object in store from claims
   * @param claims containting user information
   */

  private getUserInfo() {
    // Get user permissions from auth service

    this.authorisationService.getCurrentUser().pipe(takeUntil(this._destroying$)).subscribe((response) => {
      this.store.dispatch(setUserInformation({ user: response }));
    });
  }

  /**
   * Redirects to login page
   */
  login() {
    this.authService.loginWithRedirect().pipe(takeUntil(this._destroying$)).subscribe();
  }

  /**
   * Redirects to logout page
   */
  logout(): void {
    this.authService.logout({ logoutParams: { returnTo: environment.routes.baseUrl } });
  }

  getLoginStatus(): Observable<boolean> {
    return this.isUserLoggedIn$.asObservable();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
