import { DestroyRef, Injectable, signal } from "@angular/core";
import { MarkdownService } from "../http/markdown.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { marked } from 'marked';

@Injectable({
  providedIn: 'root'
})
export default class ChangeLogHelper {
  changeLogEntries = signal<string[]>([]);
  currentVersion = signal<string | undefined>(undefined);
  newLogsCount = signal(0);

  constructor(
    private markdownService: MarkdownService,
    private destroyRef: DestroyRef
  ) {
    // Note that Angular automatically sanitizes - ref https://angular.io/guide/security#sanitization-and-security-contexts
    this.markdownService.getMarkdownByPath('assets/changelogs-no.md').pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(markdown => {
        const changelog = marked.parse(markdown) as string;
        this.changeLogEntries.set(changelog.split('<p><a name="').map(entry => entry.split('"></a></p>')[1]).slice(1))

        // Get all versions from the changelog
        const versionRegex = /releases\/tag\/v([0-9]+\.[0-9]+\.[0-9]+)"/;
        const versions = this.changeLogEntries().map(entry => {
          const match = entry.match(versionRegex);
          // Extract the version number from the match result
          return match ? match[1] : undefined;
        }).filter(version => !!version);

        // Set the current version to the latest version
        this.currentVersion.set(versions[0]);

        // Get the users last viewed version
        const lastViewedVersion = localStorage.getItem('lastViewedChangelog') ?? versions[versions.length - 1];
        const lastViewedIndex = versions.indexOf(lastViewedVersion);

        // Set the new logs count
        this.newLogsCount.set(lastViewedIndex === -1 ? versions.length : lastViewedIndex);
      });
  }

  getChangeLogEntries() {
    return this.changeLogEntries();
  }

  saveLastViewedVersion(): void {
    if (this.currentVersion()) {
      localStorage.setItem('lastViewedChangelog', this.currentVersion()!);
      this.newLogsCount.set(0);
    }
  }

  getNewLogsCount() {
    return this.newLogsCount();
  }
}
