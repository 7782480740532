{
  "alarm": {
    "activeComment": "Gjeldende kommentar",
    "alarmCommentHistory": "Kommentarhistorikk",
    "alarmConfig": {
      "config": "Alarmkonfigurasjon",
      "error": {
        "max": "Må være mindre enn {{max}}",
        "min": "Må være større enn {{min}}",
        "pattern": "Kan kun være tall."
      },
      "history": "Historikk alarmkonfigurasjon",
      "label": {
        "comment": "Kommentar",
        "pumpMissingSalesNotificationPriority": "Prioritet varsling manglende salg",
        "pumpMissingSalesThreshold": "Terskel antall manglende forventede salg",
        "pumpNotificationPriority": "Prioritet pumpevarsler",
        "pumpThreshold": "Minutterskel pumpevarsler",
        "receiptEmptyPaperErrorNotificationPriority": "Prioritet varsling tomt papir",
        "receiptErrorNotificationPriority": "Prioritet varsling kvitteringsfeil",
        "receiptLowPaperErrorNotificationPriority": "Prioritet varsling lite papir",
        "receiptPaperJamErrorNotificationPriority": "Prioritet varsling papirstopp",
        "stationMissingSalesNotificationPriority": "Prioritet varsling manglende salg",
        "stationMissingSalesThreshold": "Terskel antall manglende forventede salg",
        "stationOfflineNotificationPriority": "Prioritet varsling offline",
        "stationOnlineNotificationPriority": "Prioritet varsling online",
        "terminalMissingSalesNotificationPriority": "Prioritet varsling manglende salg",
        "terminalMissingSalesThreshold": "Terskel antall manglende forventede salg",
        "terminalNotificationPriority": "Prioritet terminalvarsler",
        "terminalThreshold": "Minutterskel terminalvarsler",
        "zeroFillingNotificationPriority": "Prioritet nullfyllingsvarsler",
        "zeroFillingQuantityErrorValue": "Kvantitetsgrense nullfyllingsvarsler",
        "zeroFillingQuantityResetValue": "Resetverdi nullfyllinger",
        "zeroFillingThreshold": "Terskel antall nullfyllinger på rad"
      },
      "priority": {
        "-1": "Lav",
        "-2": "Lavest",
        "0": "Normal",
        "1": "Høy",
        "2": "Kritisk"
      },
      "tooltip": {
        "pumpMissingSalesThreshold": "Antall salg færre enn forventet (basert på median de siste 20 ukene, i tilsvarende tidsrom av dagen) per pumpe",
        "pumpThreshold": "Antall minutter det ikke har kommet nye hendelser etter feilmelding fra pumpen, før alarm utløses",
        "stationMissingSalesThreshold": "Antall salg færre enn forventet (basert på median de siste 20 ukene, i tilsvarende tidsrom av dagen) per stasjon",
        "terminalMissingSalesThreshold": "Antall salg færre enn forventet (basert på median de siste 20 ukene, i tilsvarende tidsrom av dagen) per terminal",
        "terminalThreshold": "Antall minutter fra siste terminalhendelse, før alarm utløses",
        "zeroFillingQuantityErrorValue": "Antall Liter som er terskel for nullfyllingsvarsler",
        "zeroFillingQuantityResetValue": "Antall Liter som må fylles for å resette et nullfyllingsvarsel",
        "zeroFillingThreshold": "Antall nullfyllinger på rad før alarm utløses"
      },
      "type": {
        "pump": "Pumpe",
        "receipt": "Kvittering",
        "station": "Stasjon",
        "terminal": "Terminal",
        "zeroFilling": "Nullfylling"
      },
      "unit": {
        "pumpMissingSalesThreshold": "ant.",
        "pumpThreshold": "min",
        "stationMissingSalesThreshold": "ant.",
        "terminalMissingSalesThreshold": "ant.",
        "terminalThreshold": "min",
        "zeroFillingQuantityErrorValue": "Liter",
        "zeroFillingQuantityResetValue": "Liter",
        "zeroFillingThreshold": "ant."
      }
    },
    "alarmType": {
      "0": "Udefinert",
      "1": "Terminal",
      "100": "Dummy",
      "2": "Pumpe",
      "3": "Printer",
      "4": "Kvittering",
      "5": "Nullfylling",
      "6": "Stasjon",
      "type": "Alarmtype"
    },
    "alarms": "Alarmer",
    "device": "Enhet",
    "history": "Alarmhistorikk",
    "lastComment": "Siste kommentar",
    "messages": {
      "cannotGetAlarms": "Kunne ikke hente alarmer.",
      "cannotGetComments": "Kunne ikke hente kommentarer.",
      "cannotGetConfig": "Kunne ikke hente alarmkonfigurasjon.",
      "cannotGetConfigs": "Kunne ikke hente alarmkonfigurasjoner.",
      "cannotResetDeviceAlarm": "Kunne ikke resette alarmen for enheten.",
      "cannotSaveComment": "Kunne ikke lagre kommentar.",
      "cannotSaveConfig": "Kunne ikke lagre alarmkonfigurasjon.",
      "commentSaved": "Kommentar er lagret.",
      "configSaved": "Alarmkonfigurasjonen er lagret.",
      "deviceAlarmReset": "Alarm for enheten er resatt.",
      "resetDeviceAlarm": "Er du sikker på at du vil resette enhet {{ device }} på {{ station }}?",
      "resetStationAlarm": "Er du sikker på at du vil resette stasjonsalarmen på {{ station }}?"
    },
    "monitoring": "Alarmmonitorering",
    "noAlarms": "Ingen alarmer",
    "noAlarmsToShow": "Ingen alarmer å vise.",
    "noComment": "Ingen kommentar",
    "noComments": "Ingen kommentar å vise.",
    "resetDeviceAlarm": "Resette alarm?",
    "severityLevel": {
      "0": "Ingen",
      "1": "Lav",
      "2": "Middels",
      "3": "Høy",
      "4": "Kritisk",
      "level": "Alvorlighetsgrad"
    }
  },
  "alarmHistory": {
    "alarmType": "Alarmtype",
    "cannotGetHistory": "Kunne ikke hente alarmhistorikk",
    "cannotGetStatuses": "Kunne ikke hente tilgjengelige statuser",
    "deviceAlarmDate": "Dato og tid",
    "deviceId": "Enhet",
    "isError": "Feilikon",
    "nozzleId": "Pistol",
    "selectAlarmTypes": "Alarmtyper",
    "selectStatuses": "Statuser",
    "statusDescription": "Status"
  },
  "common": {
    "active": "Aktiv",
    "add": "Legg til",
    "after": "Etter",
    "all": "Alle",
    "and": "Og",
    "back": "Tilbake",
    "batch": "Batch",
    "before": "Før",
    "cancel": "Avbryt",
    "choose": "Velg",
    "clear": "Tøm",
    "close": "Lukk",
    "comment": "Kommentar",
    "create": "Opprett",
    "created": "Opprettet",
    "createdBy": "Opprettet av",
    "date": "Dato",
    "dateAndTime": "Dato og tid",
    "delete": "Slett",
    "detailsFor": "Detaljer for",
    "edit": "Rediger",
    "error": "En feil har oppstått.",
    "errorInForm": "Skjemaet inneholder feil.",
    "for": "For",
    "from": "Fra",
    "here": "Her",
    "id": "ID",
    "inactive": "Inaktiv",
    "invalidDateFormatHint": "Ugyldig datoformat",
    "invalidFormatHint": "Ugyldig format",
    "invalidTimeFormatHint": "Ugyldig tidsformat",
    "lastChanged": "Sist endret",
    "lastModified": "Sist endret",
    "lastModifiedBy": "Sist endret av",
    "lastUpdated": "Sist oppdatert",
    "loading": "Laster inn",
    "maxLengthHint": "Maks {{ maxLength }} tegn",
    "maxValueHint": "Maks {{ maxValue }}",
    "minLargerThanMaxHint": "Minsteverdi må være mindre enn maksverdi",
    "minLengthHint": "Minst {{ minLength }} tegn",
    "minValueHint": "Minst {{ minValue }}",
    "missingRequired": "Obligatoriske felter må fylles",
    "more": "Mer",
    "name": "Navn",
    "new": "Ny",
    "next": "Neste",
    "no": "Nei",
    "ok": "OK",
    "operations": "Redigering",
    "reason": "Årsak",
    "refresh": "Refresh",
    "register": "Registrer",
    "registered": "Registrert",
    "registeredBy": "Registrert av",
    "remove": "Fjern",
    "removeFilter": "Fjern filter",
    "requiredFieldHint": "Feltet må fylles",
    "requiredSelectHint": "En verdi må velges",
    "save": "Lagre",
    "search": "Søk",
    "selectStation": "Velg stasjon",
    "selectStationGroup": "Velg stasjonsgruppe",
    "selectStationGroups": "Velg stasjonsgrupper",
    "selectStations": "Velg stasjoner",
    "showLess": "Se mindre",
    "showMore": "Se mer",
    "stations": "Stasjoner",
    "status": "Status",
    "summary": "Oppsummering",
    "system": "System",
    "time": "Tidspunkt",
    "to": "Til",
    "type": "Type",
    "units": {
      "h": "t",
      "hours": "timer",
      "min": "min",
      "minutes": "minutter"
    },
    "unknown": "Ukjent",
    "update": "Oppdater",
    "updated": "Oppdatert",
    "yes": "Ja"
  },
  "confirmations": {
    "confirm": "Bekreft",
    "permanentlyDeleted": "Vil bli permanent slettet.",
    "youSure": "Er du sikker?"
  },
  "deviations": {
    "acceptButton": "Godkjenn",
    "accepted": "Manuelt",
    "acceptedHandling": "Utført",
    "approver": "Godkjent av",
    "areYouSureDeleteTransaction": "Er du sikker på at du vil slette dette salget? Det vil ikke bli en del av oppgjøret, og vil ikke bli sendt til Visma.",
    "automatic": "Automatisk",
    "batch": "Batch",
    "cannotGetNotRunSettlements": "Kunne ikke hente ikke-fullførte oppgjør",
    "cannotOpenAcceptedDeviation": "Kunne ikke åpne info for godkjent oppgjør",
    "deleteTransaction": "Slett salg",
    "deviations": "Avvik",
    "differential": "Diff.",
    "endDate": "Dato",
    "errorMessage": "Feilmelding",
    "errorMessages": "Feilmeldinger",
    "failedTransactionsLoadFailed": "Kunne ikke hente feilede salg.",
    "handle": "Håndter",
    "handledEndOfDay": "Aut. håndtert",
    "handling": {
      "acceptDeviationConfirmation": "Er du sikker på at du vil godkjenne dette avviket?",
      "label": "Behandle avvik"
    },
    "hideAcceptedDeviations": "Skjul",
    "input": {
      "noComment": "Kommentar"
    },
    "label": "Oppgjørsdata",
    "maskedCardNumberError": "Kortnummer kan ikke være maskert",
    "messages": {
      "cannotAcceptDeviation": "Kunne ikke godkjenne avvik",
      "cannotSaveComment": "Kunne ikke lagre kommentar",
      "cannotUpdateDeviation": "Kunne ikke rekjøre oppgjør",
      "updateSale": "Er du sikker på at du vil oppdatere salget?"
    },
    "noDeviations": "Ingen avvik å vise.",
    "noErrorMessages": "Ingen feilmeldinger å vise.",
    "nr": "Oppgjørsnr.",
    "oldUnhandeledDeviations": "Ikke-fullførte oppgjør: {{ nrSettlements }}",
    "payexCount": "Ant. PayEx",
    "payexTotal": "PayEx total",
    "posAmount": "Beløp: {{amount}}",
    "posCardNumber": "Kortnummer: {{cardNumber}}",
    "posCardType": "Korttype: {{cardType}}",
    "posCount": "Ant. Pos",
    "posDateTimeOfSale": "Dato: {{date}}",
    "posTotal": "Pos total",
    "reason": "Godkjenners kommentar",
    "rerun": "Rekjørt",
    "resetDate": "Tilbakestill dato",
    "saleUpdatedError": "Salget kunne ikke oppdateres",
    "saleUpdatedSuccess": "Salget er oppdatert",
    "salesLoadFailed": "Kunne ikke hente salg.",
    "showAcceptedDeviations": "Håndterte oppgjør",
    "station": "Stasjon",
    "success": {
      "acceptedDeviation": "Avvik godkjent",
      "commentSaved": "Kommentar lagret",
      "updatedDeviation": "Oppgjør rekjøres"
    },
    "transactionDeleteFailed": "Kunne ikke slette salg",
    "transactionDeleted": "Salget er slettet",
    "transactions": {
      "failedTransactions": "Feilede salg",
      "manuelTransactions": "Manuelle salg",
      "source": "Kilde"
    },
    "updateButton": "Rekjør oppgjør",
    "updatePan": "Oppdater kortnummer",
    "updatePosSaleHeader": "Oppdater POS-salg",
    "updateSale": "Oppdater salg"
  },
  "fuel": {
    "add": "Legg til produkt",
    "correlationId": "KorrelasjonsID",
    "created": "Opprettet",
    "deleteFuel": "Slett produkt",
    "description": "Beskrivelse",
    "descriptions": {
      "englishDescription": "Engelsk navn, brukes ut til eksterne partnere",
      "productGroup": "Hovedbok/PayEx",
      "quality": "FuelGrade"
    },
    "englishDescription": "Engelsk navn",
    "fuel": "Produkt",
    "fuelnumber": "Produktnummer",
    "fuels": "Produkter",
    "id": "ID",
    "messages": {
      "cannotDelete": "Kan ikke slette produktet.",
      "cannotSave": "Kan ikke lagre produktet.",
      "created": "Produktet er opprettet.",
      "deleteFuel": "Produkt {{ fuelDescription }} ({{ fuelCorrelation }}) vil bli permanent slettet.",
      "deleted": "Produktet er slettet.",
      "updated": "Produktet er oppdatert."
    },
    "new": "Nytt produkt",
    "product": "Produkt",
    "productgroup": "Varegruppe",
    "quality": "Kvalitet",
    "searchProduct": "Søk etter produkt",
    "skanolId": "SkanolID",
    "vismaId": "VismaID"
  },
  "graphview": {
    "buttonDescription": "Hvis innholdet fra Qlik ikke rendrer, forsøk en refresh via knappen over.",
    "fuel": "Drivstoff",
    "incognitoDescription": "Innholdet fra Qlik vil ikke kunne rendres i et incognito vindu.",
    "wash": "Vask"
  },
  "manualTransactions": {
    "addOrUpdateCardNumber": "Legg til/oppdater kortnummer",
    "amount": "Beløp",
    "attachments": "Vedlegg",
    "cannotDeleteDocument": "Kunne ikke slette vedlegg",
    "cardNumberBillableNotMatch": "Kortnummeret tilfredstiller ikke kravene for betalingstype som skal faktureres. Krever umaskert kortnummer",
    "cardNumberError": "Feltet er ikke fylt ut eller har ugyldig verdi",
    "cardNumberNotBillableNotMatch": "Kortnummeret tilfredstiller ikke kravene for betalingstype som ikke skal faktureres. Krever maskert kortnummer",
    "date": "Kjøpsdato",
    "deleteConfirmation": "Dette vil slette {{ fileName }} fra det manuelle salget. Er du sikker?",
    "deleteFailed": "Salget kunne ikke slettes.",
    "deleted": "Salget er slettet.",
    "error": "Kunne ikke opprette salg",
    "fuel": "Produkt",
    "fuelPrice": "Literpris",
    "fuelType": "Drivstofftype",
    "fuelVolume": "Volum",
    "generate": "Generer",
    "hideSavedTransactions": "Skjul lagrede salg",
    "invalidDecimals": "Maks {{ decimals }} desimaler",
    "noSavedTransactions": "Det finnes ingen lagrede salg å vise.",
    "notAllFields": "Alle feltene må fylles ut!",
    "nrAttachments": "Antall vedlegg",
    "permanentlyDeleted": "{{ sale }} vil bli permanent slettet. Er du sikker?",
    "productType": "Produkttype",
    "receiptError": "Kunne ikke lagre kvittering",
    "saleTime": "Salgstidspunkt",
    "save": "Lagre salg",
    "sequenceNumber": "Sekvensnummer",
    "showSavedTransactions": "Vis lagrede salg",
    "success": "Salget er opprettet",
    "time": "Kjøpstid",
    "total": "Totalsum inkl. mva",
    "totalNotMatch": "Totalsum må være lik summen av volum * literpris",
    "update": "Oppdater salg",
    "upload": "Last opp vedlegg"
  },
  "menu": {
    "alarms": "Alarmer",
    "competitorConfig": "Influensområder",
    "config": "Konfigurasjon",
    "dashboard": "Dashbord",
    "deliveries": "Leveringer",
    "deviation": "Feilliste",
    "graphs": "Sekundering",
    "listprice": "Listepris",
    "logistics": "Logistikk",
    "logout": "Logg ut",
    "myPage": "Min side",
    "observationRoutes": "Kjøreruter",
    "onboarding": "Pårulling",
    "operations": "Drift",
    "pos-raw-data": "POS-eventer",
    "price": "Pris",
    "priceLift": "Prisløft",
    "priceObservations": "Observasjoner",
    "priceObservationsHistory": "Alle observasjoner",
    "priceOverview": "Prisoversikt",
    "priceRuleMangement": "Regelstyring",
    "priceRules": "Prisregler",
    "priceStationDetails": "Stasjonsdetaljer",
    "products": "Produkter",
    "registerPriceObservations": "Observer pris",
    "relays": "Reléer",
    "sales": "Salg",
    "settlement": "Oppgjør",
    "shortcuts": "Snarveier",
    "stationSettlement": "Stasjonsoppgjør",
    "stations": "Stasjoner",
    "tanks": "Tankoversikt",
    "toggleSidebar": "Toggle sidemeny",
    "unox-logo": "Uno-X logo",
    "users": "Brukere",
    "washSettlement": "Vaskeoppgjør"
  },
  "network": {
    "configureTags": "Konfigurer tagger (partnertilgang)",
    "deleteTag": "Slett tagg",
    "messages": {
      "cannotCreateTag": "Kunne ikke opprette tagg.",
      "cannotDeleteTag": "Kunne ikke slette tagg.",
      "cannotFetchTags": "Kunne ikke hente tagger.",
      "cannotUpdateTag": "Kunne ikke oppdatere tagg.",
      "createdTag": "Tagg er opprettet.",
      "deletedTag": "Tagg er slettet.",
      "tagWillBePermanentlyDeleted": "{{ tag }} vil bli permanent slettet. Er du sikker?",
      "updatedTag": "Tagg er oppdatert."
    },
    "noTags": "Ingen tagger å vise.",
    "selectStationTypesHint": "Velg stasjonstyper som skal vises ved henting av data for denne taggen.",
    "tag": "Tagg",
    "tags": "Tagger"
  },
  "onboarding": {
    "addStation": "Legg til stasjon",
    "changeStatus": "Endre pårullingsstatus",
    "chosenStations": "Valgte stasjoner",
    "dates": "Datoer",
    "editSelected": "Rediger valgte",
    "info": {
      "aborting": "På/avrulling avbrytes",
      "alreadyOnboarded": "Stasjoner er allerede pårullet.",
      "getsFollowingChanges": "stasjoner får følgende endringer:",
      "noChanges": "Ingen endringer",
      "notOnboarded": "Stasjoner er ikke pårullet.",
      "offboarding": "Avrulling",
      "onboarding": "Pårulling",
      "withinOffset": "Endring planlagt innen låst tid."
    },
    "messages": {
      "abortSuccess": "Endring av pårulling avbrutt.",
      "cannotAbort": "Endring av pårulling kunne ikke avbrytes.",
      "cannotOffboard": "Stasjonen kunne ikke avrulles.",
      "cannotOffboardGroup": "Stasjonene kunne ikke avrulles.",
      "cannotOnboard": "Stasjonen kunne ikke pårulles.",
      "cannotOnboardGroup": "Stasjonene kunne ikke pårulles.",
      "isNotOnboarded": "Stasjonen er ikke pårullet.",
      "offboardingSuccess": "Stasjonen avrulles.",
      "offboardingSuccessGroup": "Stasjonene avrulles.",
      "onboardingSuccess": "Stasjonen pårulles.",
      "onboardingSuccessGroup": "Stasjonene pårulles."
    },
    "minimumTimeNotMet": "Må være minst {{quantity}} {{unit}} frem i tid.",
    "notPlanned": "Ikke planlagt",
    "offboarded": "Avrullet",
    "offboardingDate": "Avrullingsdato",
    "onboarded": "Pårullet",
    "onboardingDate": "Pårullingsdato",
    "planned": "Planlagt",
    "plannedOffboarded": "Planlagt avrulling",
    "showAsDates": "Vis som datoer",
    "stage": "Pårullingssteg",
    "stationOverview": "Stasjonsoversikt"
  },
  "posEvents": {
    "cannotGetPosEvents": "Kunne ikke hente pos-eventer",
    "eventSequenceNumber": "Sekvensnummer",
    "freeSearchLabel": "Fritekstsøk",
    "noDataFound": "Ingen pos-eventer funnet for gitt dato:",
    "noStationSelected": "Velg stasjon for å søke etter pos-eventer"
  },
  "price": {
    "cannotRequestPrice": "Kunne ikke sende prisforespørsel.",
    "competitors": {
      "address": {
        "address": "Adresse",
        "latitude": "Breddegrad",
        "longitude": "Lengdegrad",
        "postalArea": "Poststed",
        "postalCode": "Postnummer",
        "streetAddress": "Gateadresse"
      },
      "chain": "Konkurrentkjede",
      "chainCount": "Antall konkurrentkjeder",
      "chainGroup": "Konkurrentkjedegruppe",
      "chainGroupName": "Konkurrentkjedegruppenavn",
      "chainGroups": "Konkurrentkjedegrupper",
      "chainName": "Kjedenavn",
      "chains": "Konkurrentkjeder",
      "deleteChain": "Slett konkurrentkjede",
      "deleteChainGroup": "Slett konkurrentkjedegruppe",
      "deleteStation": "Slett konkurrentstasjon",
      "editChain": "Rediger konkurrentkjede",
      "editChainGroup": "Rediger konkurrentkjedegruppe",
      "editStation": "Rediger konkurrentstasjon",
      "geoCoordinates": "Koordinater",
      "messages": {
        "cannotDeleteChain": "Kunne ikke slette konkurrentkjeden.",
        "cannotDeleteChainGroup": "Kunne ikke slette konkurrentkjedegruppen.",
        "cannotDeleteStation": "Kunne ikke slette konkurrentstasjonen.",
        "cannotGetChainGroups": "Kunne ikke hente konkurrentkjedegrupper.",
        "cannotGetChains": "Kunne ikke hente konkurrentkjeder.",
        "cannotGetStations": "Kunne ikke hente konkurrentstasjoner.",
        "cannotLoadChains": "Kunne ikke laste konkurrentkjeder.",
        "cannotSaveChain": "Konkurrentkjeden kunne ikke lagres.",
        "cannotSaveChainGroup": "Kunne ikke lagre konkurrentkjedegruppe.",
        "cannotSaveStation": "Konkurrentstasjonen kunne ikke lagres.",
        "deleteChainGroupWarning": "Dette vil slette konkurrentkjedegruppe {{ group }}. Er du sikker?",
        "deleteChainWarning": "Dette vil slette både konkurrentkjede {{ chain }} og alle konkurrentstasjoner tilknyttet kjeden. Er du sikker?",
        "deleteStationWarning": "Dette vil slette konkurrentstasjon {{ station }}. Er du sikker?",
        "deletedChain": "Konkurrentkjeden er slettet",
        "deletedChainGroup": "Konkurrentkjedegruppen er slettet.",
        "deletedStation": "Konkurrentstasjonen er slettet.",
        "invalidAddress": "Ugyldig addresse. Må være tom eller fullstendig.",
        "invalidCoordinates": "Ugyldige koordinater. Må være tom eller fullstendig.",
        "savedChain": "Konkurrentkjeden er lagret.",
        "savedChainGroup": "Konkurrentkjedegruppen er lagret.",
        "savedStation": "Konkurrentstasjonen er lagret."
      },
      "mustChooseChainHint": "Må velge kjede i listen",
      "newChain": "Ny konkurrentkjede",
      "newStation": "Ny konkurrentstasjon",
      "noChainGroups": "Ingen konkurrentkjedegrupper å vise.",
      "noChains": "Ingen konkurrentkjeder å vise.",
      "noChainsDescription": "Ingen konkurrentkjeder å velge, derfor kan ikke konkurrentstasjoner opprettes.",
      "noStations": "Ingen konkurrentstasjoner å vise.",
      "search": {
        "addCompetitorStation": "Legg til konkurrentstasjon",
        "addCompetitorStationOrInfluenceRegion": "Legg til konkurrentstasjon eller influensområde",
        "addInfluenceRegion": "Legg til influensområde",
        "competitorStations": "Søk etter konkurrentstasjoner",
        "competitorStationsOrInfluenceRegions": "Søk etter konkurrentstasjoner eller influensområder",
        "influenceRegions": "Søk etter influensområder"
      },
      "shouldInfluencePrice": "Påvirker pris",
      "shouldNotInfluencePrice": "Påvirker ikke pris",
      "station": "Konkurrentstasjon",
      "stations": "Konkurrentstasjoner"
    },
    "configuration": {
      "general": "Generelle regler",
      "generalConfig": {
        "maxAllowedAdjustment": "Største tillatte justering",
        "maxPrice": "Makspris",
        "minPrice": "Minstepris",
        "roundingSelfService": "Øreavrunding Automat-stasjoner"
      },
      "messages": {
        "cannotGet": "Kunne ikke hente konfigurasjon.",
        "cannotGetDeviations": "Kunne ikke hente stedstillegg.",
        "cannotGetListPrices": "Kunne ikke hente listepriser.",
        "cannotSave": "Konfigurasjonen kunne ikke lagres.",
        "cannotSaveListPrices": "Listepriser kunne ikke lagres.",
        "listPricesSaved": "Listepriser er lagret",
        "saved": "Konfigurasjonen er lagret."
      },
      "pricetype": "Prismodell per produkt",
      "pricetypes": {
        "None": "Skal ikke prises",
        "WithObservation": "Prises med observasjon",
        "WithoutObservation": "Prises uten observasjon"
      }
    },
    "dateLabels": {
      "calculatedAt": "Initiert",
      "confirmedAt": "Bekreftet",
      "publishedAt": "Publisert"
    },
    "deviation": "Stedstillegg",
    "error": {
      "cannotGetListPrices": "Kunne ikke hente listepriser.",
      "cannotGetPrices": "Kunne ikke hente priser.",
      "cannotSaveManualPrices": "Kunne ikke lagre manuelle priser."
    },
    "events": "Prishendelser (workflow)",
    "expected": "Forventet",
    "history": "Prishistorikk",
    "influenceRegion": {
      "comment": "Kommentar",
      "competitorStations": "Konkurrentstasjoner",
      "delete": "Slett influensområde",
      "edit": "Rediger influensområde",
      "messages": {
        "cannotDeleteInfluenceRegion": "Kunne ikke slette influensområde.",
        "cannotGetInfluenceRegions": "Kunne ikke hente influensområder.",
        "cannotLoadCompetitorStations": "Kunne ikke laste konkurrentstasjoner.",
        "cannotLoadStations": "Kunne ikke laste egne stasjoner.",
        "cannotSaveInfluenceRegion": "Kunne ikke lagre influensområde.",
        "deleteInfluenceRegion": "Er du sikker på at du vil slette influensområde {{ region }}?",
        "influenceRegionDeleted": "Influensområdet er slettet.",
        "influenceRegionSaved": "Influensområde er lagret."
      },
      "name": "Navn",
      "new": "Nytt influensområde",
      "region": "Influensområde",
      "regions": "Influensområder",
      "search": "Søk etter influensområder",
      "stations": "Egne stasjoner",
      "view": "Vis influensområde"
    },
    "listprice": {
      "editListPrice": "Endre listepriser",
      "history": "Historikk listepris",
      "listprices": "Listepriser",
      "perProduct": "Listepris per produkt"
    },
    "log": {
      "amount": "Endring",
      "calculatedPrice": "Beregnet",
      "cannotGet": "Kunne ikke hente prisberegningslogg.",
      "log": "Prisberegningslogg",
      "noLog": "Ingen logg å vise.",
      "noLogs": "Ingen logger å vise.",
      "step": "Steg",
      "triggeredBy": "Trigget av"
    },
    "manualPricing": "Manuell prising",
    "messages": {
      "cannotGetPrices": "Kunne ikke hente priser.",
      "cannotGetProducts": "Kunne ikke hente produkter.",
      "cannotLoadInfluences": "Kunne ikke hente påvirkede stasjoner.",
      "noPriceLog": "Ingen prislogg å vise."
    },
    "minOnePrice": "Minst en pris må fylles ut",
    "newPrice": "Ny pris",
    "noInfluencedStationsToShow": "Ingen påvirkede stasjoner å vise.",
    "noManuallyPricableProducts": "Ingen produkter kan prises manuelt.",
    "noPrices": "Ingen priser å vise.",
    "observation": {
      "messages": {
        "cannotGetProducts": "Kunne ikke hente produkter."
      }
    },
    "observationRoute": {
      "selectRoute": "Velg kjørerute"
    },
    "observationRoutes": {
      "activateRoutes": "Aktiver kjøreruter",
      "activeRoute": "Aktiv kjørerute",
      "addRoute": "Ny kjørerute",
      "atLeastOneItemHint": "Minst en stasjon må velges.",
      "createRoutes": "Lag ruter",
      "deactivateRoutes": "Kjøreruter kan deaktiveres",
      "deleteDialogText": "Er du sikker på at du vil slette kjøreruten?",
      "dragDropHint": "Dra stasjonene i riktig rekkefølge ved hjelp av ikon-håndtakene til venstre.",
      "isActive": "Kjøreruter er aktive for denne enheten.",
      "messages": {
        "deleteRoute": "Slett rute",
        "routeCannotDelete": "Kjøreruten kunne ikke slettes.",
        "routeCannotGet": "Kunne ikke hente kjøreruter.",
        "routeCannotGetActive": "Kunne ikke hente aktiv kjørerute.",
        "routeCannotSave": "Kjøreruten kunne ikke lagres.",
        "routeDeleted": "Kjøreruten har blitt slettet.",
        "routeSaved": "Kjøreruten er lagret."
      },
      "myRoutes": "Mine kjøreruter",
      "noRoute": "Ingen kjørerute",
      "noRoutes": "Ingen kjøreruter å vise.",
      "noSelectedStations": "Ingen stasjoner valgt",
      "notActive": "Kjøreruter er ikke aktive for denne enheten. ",
      "order": "Rekkefølge",
      "route": "Kjørerute",
      "routeName": "Rutenavn",
      "selectRoute": "Velg kjørerute",
      "startRoute": "Start kjørerute"
    },
    "observations": {
      "cannotGetObservations": "Kunne ikke hente observasjoner",
      "delete": "Slett observasjon",
      "deleteAll": "Slett observasjoner",
      "highPriceDifference": "Høy prisdifferanse",
      "lastSentObservations": "Sist sendte observasjoner",
      "newObservation": "Ny observasjon",
      "noObservations": "Ingen observasjoner å vise",
      "notInfluencing": "Påvirker ikke pris",
      "numberOfInfluencedStations": "Påvirkede stasjoner",
      "observationRegistered": "Observasjonen ble registrert",
      "observationSignificantPriceDrop": "Følgende priser er mer enn 1 kr lavere enn den laveste prisen i tilhørende influensområde:\n {{prices}} \n\nEr du sikker på at du vil sende inn?",
      "observations": "Observasjoner",
      "observedAt": "Observert",
      "observedBy": "Observert av",
      "observedPrices": "Observerte priser",
      "ownObservations": "Egne observasjoner",
      "register": "Registrer",
      "registerWithoutChange": "Uten påvirkning",
      "registeredObservations": "Registrerte observasjoner",
      "removeAllObservations": "Er du sikker på at du vil fjerne alle observasjoner som ikke er sendt?",
      "removeObservation": "Er du sikker på at du vil fjerne observasjonen på {{stationName}} som ikke er sendt?",
      "seeInfluencedStations": "Se påvirkede stasjoner",
      "selectStation": "Velg stasjon",
      "send": "Send",
      "stationHasPrice": "Det er allerede registrert en observasjon på stasjonen.",
      "stationsInfluencedBy": "Stasjoner påvirket av observasjon på ",
      "status": {
        "0": "Udefinert",
        "1": "Opprettet",
        "2": "Publisert",
        "3": "Har medført prisendring",
        "4": "Feilet",
        "5": "Republisert",
        "6": "Påvirker ikke pris",
        "7": "Har ikke medført prisendring",
        "8": "Prislås"
      }
    },
    "openLog": "Åpne prislogg",
    "overview": "Prisoversikt",
    "price": "Pris",
    "priceLift": {
      "addFuel": "Legg til produkter",
      "addInfluenceRegion": "Legg til influensområde",
      "addProduct": "Legg til produkt",
      "addStationGroup": "Legg til stasjonsgruppe",
      "deletePriceLift": "Slett prisløft",
      "difference": "Diff. nåpris",
      "edit": "Rediger prisløft",
      "editPriceLift": "Rediger prisløft",
      "error": {
        "invalidListPrices": "En eller flere listepriser har ugylig verdi",
        "missingGroup": "Prisløft må være knyttet til en stasjonsgruppe",
        "missingProduct": "Prisløft må være knyttet til minst ett produkt",
        "stationGroupOrInfluenceRegionMustBeFilled": "Prisløft må være knyttet til en stasjonsgruppe eller et influensområde",
        "timeAfterNow": "Publiseringstidspunkt må være etter nåværende tidspunkt",
        "wrongTimeFormat": "Ugyldig formattering av tidspunkt"
      },
      "historicalLifts": "Historiske prisløft",
      "influenceRegions": "Influensområder",
      "listprice": "Listepris",
      "longPriceLock": "Lang prislås",
      "messages": {
        "abnormallyLongPriceLock": "Prislås er unormalt lang: {{ hours }}t {{ minutes }}min",
        "cannotCreatePriceLift": "Prisløftet kunne ikke opprettes.",
        "cannotDeletePriceLift": "Prisløftet kunne ikke slettes.",
        "cannotGetListPrices": "Kunne ikke hente listepriser.",
        "cannotGetPriceLifts": "Kunne ikke hente prisløft.",
        "cannotLoadInfluenceRegions": "Kunne ikke laste influensområder.",
        "cannotLoadListPrices": "Kunne ikke hente listepriser.",
        "cannotLoadProducts": "Kunne ikke laste produkter.",
        "cannotUpdatePriceLift": "Prisløftet kunne ikke oppdateres.",
        "newLiftHint": "F.eks. Hvilken kjede løftet først, og når?",
        "noFailedPrices": "Ingen stasjoner feilet dette løftet",
        "priceLiftCreated": "Prisløftet er opprettet.",
        "priceLiftDeleted": "Prisløftet er slettet.",
        "priceLiftUpdated": "Prisløftet er oppdatert.",
        "priceLockHint": "Lengde prislås: {{ hours }}t {{ minutes }}m",
        "willBeDeleted": "Prisløftet vil bli slettet, og dermed ikke utført. Er du sikker?"
      },
      "newPrice": "Ny pris",
      "newPriceLift": "Nytt prisløft",
      "noPriceLifts": "Ingen prisløft å vise",
      "oldPrice": "Nåværende",
      "plannedLifts": "Planlagte prisløft",
      "priceLift": "Prisløft",
      "priceLock": "Prislås avslutningstidspunkt",
      "priceLockDuration": "Lengde prislås",
      "priceLockHourUpdated": "Timeantall oppdatert fordi minuttverdi høyere enn en time.",
      "priceType": {
        "0": "Ukjent",
        "1": "Truck",
        "5": "Bilist"
      },
      "rerun": "Rekjør feilet løft",
      "rerunAll": "Rekjør alle",
      "rerunFailed": "Rekjør feilede stasjoner",
      "rerunSelected": "Rekjør valgte",
      "selectAll": "Velg alle",
      "shouldPublishAt": "Publiseringstidspunkt",
      "stationGroups": "Stasjonsgrupper",
      "stationType": "Pristype",
      "status": {
        "0": "Kunne ikke hente status",
        "1": "Planlagt",
        "2": "Publisert",
        "3": "Bekreftet",
        "4": "Feilet",
        "5": "Republisert"
      },
      "type": {
        "1": "Truckpriser",
        "5": "Bilistpriser"
      }
    },
    "priceRequested": "Prisforespørsel sendt.",
    "priceRule": {
      "archived": "Arkivert",
      "view": "Vis"
    },
    "priceRules": {
      "activeInactive": "Aktive/inaktive regler",
      "archiveCount": "Iterasjoner i arkiv",
      "archiveFor": "Arkiv for",
      "backToArchive": "Tilbake til arkiv",
      "config": "Generell priskonfigurasjon",
      "graduationStation": "Stasjon å graduere mot",
      "isActive": "Aktiv",
      "isInactive": "Inaktiv",
      "messages": {
        "atLeastOneValueConceptDistance": "Alle konseptdistanser må ha enten en konkurrentstasjon, en konkurrentkjede eller en konkurrentkjedegruppe.",
        "atLeastOneValueInternal": "Regelen må ha enten en stasjon, en stasjonsgruppe eller et influensområde.",
        "cannotDelete": "Prisregelen kunne ikke slettes.",
        "cannotGet": "Kunne ikke hente prisregler.",
        "cannotGetData": "Kunne ikke hente nødvendig data.",
        "cannotSave": "Prisregelen kunne ikke lagres.",
        "cannotUpdate": "Kunne ikke oppdatere prisregel.",
        "priceRuleDeleted": "Prisregelen er slettet.",
        "priceRuleSaved": "Prisregelen er lagret.",
        "priceRuleUpdated": "Prisregel oppdatert."
      },
      "name": "Regelnavn",
      "newPriceRule": "Ny prisregel",
      "onlyActive": "Kun aktive",
      "onlyInactive": "Kun inaktive",
      "openForDetails": "Se detaljer",
      "priceRule": "Prisregel",
      "priceRules": "Prisregler",
      "productDelta": "Pris delta",
      "ruleType": "Regeltype",
      "ruleTypeEnum": {
        "1": "Konseptdistanse",
        "2": "Graduering",
        "3": "Produkt delta",
        "4": "Listeprisavstand"
      },
      "ruleTypes": {
        "conceptDistance": "Konseptdistanse",
        "graduation": "Graduering",
        "listPriceDelta": "Listeprisavstand",
        "productDelta": "Produkt delta"
      },
      "seeArchive": "Se arkiverte versjoner",
      "setActive": "Sett aktiv",
      "setInactive": "Sett inaktiv"
    },
    "prices": "Priser",
    "products": "Produkter",
    "requestPrice": "Send prisforespørsel",
    "searchSelectionUnavaliable": "Det er ingen konkurrentstasjoner eller influensområder som kan søkes etter. ",
    "setManual": "Sett pris",
    "stationDetails": {
      "messages": {
        "cannotGetDetails": "Kunne ikke hente stasjonsdetaljer."
      },
      "noGroups": "Ingen stasjonsgrupper å vise",
      "noInfluenceRegion": "Har ikke et influensområde å vise",
      "noRules": "Ingen regler å vise",
      "noStationSelected": "Ingen stasjon valgt",
      "priceRulesImpactedByStation": "Prisregler som stasjonen påvirker",
      "priceRulesImpactingStation": "Prisregler som påvirker stasjonen"
    },
    "status": {
      "0": "Ingen",
      "1": "Opprettet",
      "2": "Publisert",
      "3": "Forkastet",
      "4": "Feilet",
      "5": "Bekreftet",
      "6": "Republisert",
      "7": "Forespurt",
      "8": "Prislås"
    },
    "success": {
      "savedManualPrices": "Manuelle priser er lagret."
    },
    "trigger": {
      "0": "Udefinert",
      "1": "Manuell pris",
      "2": "Prisløft",
      "3": "Observasjon",
      "4": "Prisbekreftelse",
      "5": "Prisforespørsel",
      "6": "Graduering",
      "all": "Alle"
    }
  },
  "roles": {
    "MA": "MA",
    "administrator": "Admin",
    "back-office": "BackOffice",
    "logistics": "Logistikk",
    "marketing-operator": "Markedsansvarlig",
    "no-access": "Ingen tilgang",
    "observator": "Observatør"
  },
  "search": {
    "influenceRegion": "Influensområde",
    "influenceRegions": "Influensområder",
    "searchFor": "Søk etter stasjoner",
    "searchForStationGroup": "Søk etter stasjonsgruppe",
    "searchForStationOrStationGroup": "Søk etter stasjon eller stasjonsgruppe",
    "searchForStationStationGroupOrInfluenceRegion": "Søk etter stasjon, stasjonsgruppe eller influensområde",
    "station": "Stasjon",
    "stationGroup": "Stasjonsgruppe",
    "stationGroups": "Stasjonsgrupper",
    "stations": "Stasjoner"
  },
  "settlement": {
    "amount": "Beløp",
    "batch": "Batch",
    "cannotSaveSettlement": "Kunne ikke lagre oppgjør.",
    "confirmations": {
      "deleteSettlement": "Vil du slette oppgjøret?",
      "forceRecon": "Vil du tvinge avstemming?"
    },
    "currency": {
      "578": "NOK",
      "NOK": "NOK"
    },
    "dateTimeOfSale": "Salgstidspunkt",
    "edit": "Rediger oppgjør",
    "endDate": "Sluttdato",
    "error": {
      "cannotDeleteSettlement": "Kunne ikke slette oppgjør.",
      "cannotForceRecon": "Kunne ikke tvinge avstemming.",
      "cannotGenerateReceiptPdf": "Kunne ikke generere kvittering.",
      "cannotGetPaymentTypes": "Kunne ikke hente korttyper.",
      "cannotGetSales": "Kan ikke hente salg.",
      "cannotGetSettlements": "Kan ikke hente oppgjør.",
      "cannotOpenDocument": "Kan ikke åpne dokument.",
      "cannotSaveSettlement": "Kunne ikke lagre oppgjør."
    },
    "forceRecon": "Tving avstemming",
    "incompleteFormHint": "Merk at skjemaet er ufullstendig. Det kan lagres slik, men oppgjøret vil ikke kjøre før alle verdier er fylt ut.",
    "new": "Nytt oppgjør",
    "noSettlements": "Det finnes ingen oppgjør å vise.",
    "noTransactions": "Det finnes ingen salg å vise.",
    "nr": "Oppgjørsnr.",
    "nrSales": "Ant. salg",
    "nrSalesShort": "Ant. tran.",
    "otherItems": "Andre varer",
    "paymentProviderReference": "Host Batch Number (Payment Provider Reference)",
    "periodEnd": "Periodeslutt",
    "periodStart": "Periodestart",
    "price": "Pris",
    "quantity": "Mengde",
    "reconStatus": {
      "error": "Avstemming feilet",
      "heading": "Avstemming",
      "missing": "Mangler: {{missing}}",
      "noMissing": "Ingen mangler, men avstemming er ikke fullført",
      "openOrClose": "Åpning/lukking",
      "paymentProviderRecon": "Recon-fil fra PayEx",
      "paymentProviderRef": "Manglende host batch number",
      "pointOfSaleRecon": "Recon-fil fra kassa",
      "saleVerification": "Salgsverifisering fra PayEx",
      "showSuccessful": "Vis vellykkede avstemminger",
      "success": "Avstemming vellykket"
    },
    "reconStatusEnum": {
      "0": "Ikke kjørt",
      "1": "Feilet",
      "2": "Vellykket"
    },
    "referenceId": "Oppgjør nr.",
    "reportId": "Sekvensnummer periode",
    "sales": "Salg",
    "salesNr": "Salgsnummer",
    "search": "Søk etter",
    "seeTransactions": "Se salg",
    "sequenceEnd": "PeriodChange ID close",
    "sequenceStart": "PeriodChange ID open",
    "settlementFor": "Oppgjør for",
    "settlements": "Oppgjør",
    "startDate": "Startdato",
    "station": "Stasjon",
    "success": {
      "forceRecon": "Avstemming tvinges.",
      "settlementDeleted": "Oppgjør slettet."
    },
    "tax": "Avgift",
    "total": "Totalbeløp",
    "totalShort": "Total",
    "unitPrice": "Enhetspris",
    "warning": {
      "mustBeBeforeNow": "Dato må være før nåværende tidspunkt",
      "mustStopAfterStart": "Sluttdato må være etter startdato",
      "noForceRun": "Kan kun tvinge avstemming når oppgjøret er lukket, begge recon-filene er mottatt, men salgsverifisering fra PayEx eller POS mangler."
    }
  },
  "shortcuts": {
    "notificationPlan": "Varslingsplan"
  },
  "station": {
    "addStation": "Legg til stasjon",
    "addStationGroup": "Legg til stasjonsgruppe",
    "addStationOrGroup": "Legg til stasjon eller stasjonsgruppe",
    "addStationOrGroupOrRegion": "Legg til stasjon, stasjonsgruppe eller influensområde",
    "addStations": "Legg til stasjoner",
    "allStations": "Alle stasjoner",
    "amenities": {
      "amenities": "Fasiliteter",
      "edit": "Rediger fasiliteter",
      "label": {
        "has24HourRestArea": "Har 24-timers hvileområde",
        "hasCharging": "Har ladestasjon",
        "hasConvenience": "Har butikk",
        "hasTruckCharging": "Har ladestasjon for lastebil",
        "hasWash": "Har vaskehall",
        "isTruckFriendly": "Tilrettelagt for lastebil",
        "truckRoofHeightInCm": "Takhøyde for lastebil"
      }
    },
    "copyTanks": "Kopier tankoppsett",
    "correlation": "Korrelasjonsid",
    "editIpsFor": "Rediger IP-adresser for {{station}}",
    "editStations": "Rediger stasjoner",
    "error": {
      "eitherGlobalOrGroup": "Må være enten global gruppe eller ha minst to stasjoner",
      "requiredIfNotGlobal": "Påkrevd hvis ikke global gruppe"
    },
    "fusionAddress": "Stasjon IP-adresse",
    "group": {
      "deleteGroup": "Slett stasjonsgruppe",
      "edit": "Rediger stasjonsgruppe",
      "group": "Stasjonsgruppe",
      "groups": "Stasjonsgrupper",
      "isGlobal": "Global gruppe",
      "messages": {
        "deleteGroup": "Stasjonsgruppe {{ group }} vil bli permanent slettet. "
      },
      "name": "Stasjonsgruppenavn",
      "new": "Ny stasjonsgruppe",
      "noGroups": "Ingen stasjonsgrupper å vise",
      "searchFor": "Søk etter stasjonsgrupper",
      "stationCount": "Antall stasjoner",
      "theGroup": "Stasjonsgruppen",
      "view": "Vis stasjonsgruppe"
    },
    "ips": "IP-adresser",
    "messages": {
      "amenitiesUpdated": "Fasiliteter er oppdatert.",
      "cannotDelete": "Stasjonen kunne ikke slettes.",
      "cannotDeleteGroup": "Stasjonsgruppen kunne ikke slettes.",
      "cannotGetAmenities": "Kunne ikke hente fasiliteter.",
      "cannotGetGroups": "Kunne ikke hente stasjonsgrupper.",
      "cannotGetStations": "Kunne ikke hente stasjoner.",
      "cannotSave": "Stasjonen kunne ikke lagres.",
      "cannotSaveGroup": "Stasjonsgruppen kunne ikke lagres.",
      "cannotUpdateAmenities": "Kunne ikke oppdatere fasiliteter.",
      "cannotUpdateGroup": "Stasjonsgruppen kunne ikke oppdateres.",
      "cannotUpdateIps": "IP-adresser kunne ikke oppdateres.",
      "deleted": "Stasjonen er slettet.",
      "deletedGroup": "Stasjonsgruppen er slettet.",
      "ipsUpdated": "IP-adresser er oppdatert.",
      "saved": "Stasjonen er lagret.",
      "savedGroup": "Stasjonsgruppen er lagret.",
      "updatedGroup": "Stasjonsgruppen er oppdatert."
    },
    "name": "Stasjonsnavn",
    "noStationsToShow": "Ingen stasjoner å vise.",
    "nrOfTanks": "Antall tanker",
    "relay": {
      "active": "Aktiv",
      "changed": "Endret",
      "changedBy": "Endret av",
      "channel": "Kanal",
      "chooseReason": "Velg årsak",
      "confirmChannelToggle": "Er du sikker på at du vil slå {{newStatus}} kanal {{channel}} (terminal (OPT): {{terminal}}, pumpe: {{pump}}) på reléet ved {{stationName}}?",
      "confirmChannelToggleTitle": "Skru {{newStatus}}?",
      "inactive": "Inaktiv",
      "log": "Hendelseslogg",
      "messages": {
        "cannotGetLogs": "Kunne ikke hente hendelseslogger.",
        "cannotGetRelayConfig": "Kunne ikke finne relékonfigurasjon.",
        "cannotUpdateRelayConfig": "Relékonfigurasjonen kunne ikke oppdateres.",
        "relayConfigUpdated": "Relékonfigurasjonen er oppdatert."
      },
      "notDefined": "Ikke definert",
      "openLog": "Åpne hendelseslogg",
      "pump": "Pumpe",
      "reasons": {
        "fillingInProgress": "Tanking pågår",
        "missingSale": "Mangler salg",
        "other": "Annet",
        "pumpNotStarting": "Pumpe starter ikke",
        "terminalStop": "Terminal henger"
      },
      "relays": "Reléer",
      "status": {
        "0": "Ikke tilkoblet",
        "1": "På",
        "2": "Av",
        "3": "Tidsavbrudd"
      },
      "terminal": "Terminal (OPT)"
    },
    "relayAddress": "Relé IP-adresse",
    "relays": {
      "cannotGetRelayConfigs": "Kunne ikke hente reléer.",
      "edit": "Rediger reléer på {{stationName}}",
      "messages": {
        "channelToggled": "Kanalen på reléet er oppdatert."
      },
      "messsages": {
        "cannotToggleChannel": "Kanalen på reléet kunne ikke oppdateres."
      },
      "noRelayLogs": "Ingen relélogger å vise.",
      "noRelays": "Ingen reléer å vise."
    },
    "search": {
      "clear": "Tøm søk",
      "refreshAndKeep": "Oppdater og behold søk"
    },
    "selectStationTypes": "Velg stasjonstyper",
    "station": "Stasjon",
    "stationType": {
      "0": "Ukjent",
      "1": "Truck",
      "10": "Preem Sverige",
      "11": "Ladelokasjon",
      "5": "Automat",
      "6": "Vask",
      "7": "YX Norge",
      "8": "Preem Norge",
      "9": "Uno-X Danmark"
    },
    "stationTypes": "Stasjonstyper",
    "stationnr": "Stasjonsnummer",
    "stations": "Stasjoner",
    "type": "Stasjonstype"
  },
  "tank": {
    "auto": "Automatisk peiling",
    "capacity": "Størrelse",
    "changeTheoreticStatus": "Justere metode beholdsningsberegning?",
    "confirmRegister": "Vil du registrere peilingen?",
    "connectedTo": "Koblet med",
    "correlationid": "KorrelasjonsID",
    "currentFuel": "Volum",
    "currentFuelHint": "Merk: Nåværende beholdning endres ved registrering av peiling",
    "dateAndTimeForDelivery": "Tidspunkt for levering",
    "dateAndTimeForDipping": "Tidspunkt for peiling",
    "dateTimeOfReadingMax": "Kan ikke registrere peiling fram i tid",
    "deleteDipping": "Slett peiling",
    "deleteTank": "Slett tank",
    "delivery": {
      "deliveries": "Leveringer",
      "deliveryDate": "Leveringsdato",
      "deliveryTimePassed": "Forventet leveringstidspunkt er passert",
      "deliveryTooltip": "Planlagt levering {{fuelType}}:\n{{date}} - {{quantity}} {{unit}}\n\nMERK: Ikke nødvendigvis denne tanken",
      "expectedDeliveries": "Planlagte leveringer",
      "expectedDelivery": "Planlagt levering",
      "history": "Leveringshistorikk",
      "messages": {
        "cannotGetDeliveries": "Kunne ikke hente leveringer.",
        "cannotGetExpectedDeliveries": "Kunne ikke hente planlagte leveranser."
      },
      "noDeliveries": "Ingen leveringer å vise"
    },
    "differenceMessage": "Peilingen har {{literDifference}}L / {{differencePercent}}% differanse ift. forventet verdi. Stemmer dette?",
    "differenceWarning": "{{differencePercent}}% differanse ift. forventet verdi",
    "dipDay": "Peiledato",
    "dipTime": "Peiletid",
    "dipping": "Peiling",
    "dippingDelete": "Slett peiling",
    "dippingNotRegistered": "Peiling ble ikke registrert",
    "dippings": "Peilinger",
    "dippingsForTankNr": "Peilinger for tanknr.",
    "duplicateReading": "Registrer som manuell peiling",
    "duplicateReadingWarning": "Er du sikker på at du vil duplisere denne peilingen som en manuell peiling med samme volum og tidspunkt?",
    "error": {
      "invalidTime": "Ugyldig tidspunkt"
    },
    "filters": {
      "noDipping": {
        "active": "Aktive tanker",
        "all": "Alle tanker",
        "filterLabel": "Tankstatus",
        "inactive": "Inaktive tanker"
      }
    },
    "forDipping": "For peiling",
    "fuelType": "Drivstofftype",
    "gallon": "Gallon",
    "group": "Gruppe",
    "groupDiff": "Gruppediff.",
    "groupInfo": {
      "fuelsNotTheSame": "Kvalitet matcher ikke gruppen",
      "groupWillBeDeleted": "Gruppen vil bli slettet",
      "lastTankInGroup": "Siste tank i gruppen",
      "newGroup": "En ny gruppe vil bli opprettet"
    },
    "latestDelivery": "Siste levering",
    "latestDip": "Siste peiling",
    "log": "Historikk",
    "manualUpdateRequired": "Run-dry og fyllingsgrad må også sendes i VMI-skjema til Skanol",
    "maxFuelLimit": "Fyllingsgrad",
    "measured": "Målt",
    "messages": {
      "cannotChangeTheoreticStatus": "Kunne ikke endre metode for beholdsningsberegning.",
      "cannotDelete": "Tanken kunne ikke slettes.",
      "cannotDeleteDipping": "Peiling kunne ikke slettes.",
      "cannotDeleteTank": "Tanken kunne ikke slettes.",
      "cannotGetReadings": "Kunne ikke hente peilinger.",
      "cannotGetTanks": "Kunne ikke hente tanker.",
      "cannotSave": "Tanken kunne ikke lagres.",
      "cannotSaveDipping": "Peilingen kunne ikke lagres.",
      "deleteTank": "Tank {{ tank }} vil bli permanent slettet.",
      "deleted": "Tanken er slettet.",
      "dippingDeleted": "Peilingen er slettet.",
      "dippingSaved": "Peilingen er lagret.",
      "dippingWillBePermanentlyDeleted": "Peilingen vil bli permanent slettet.",
      "qualityNotEditable": "Tanken må opprettes på nytt for å endre kvalitet",
      "saved": "Tanken er lagret.",
      "stationNotEditable": "Stasjonen er ikke redigerbar.",
      "tankGroupPatternInvalid": "Må ha format 1-9."
    },
    "minFuelLimit": "Run-dry",
    "new": "Ny tank",
    "noDeliveries": "Det finnes ingen leveringer å vise",
    "noDip": "Send peiletall",
    "noDippings": "Det finnes ingen peilinger å vise",
    "noManualDippingActive": "Tanken er konfigurert til å ikke ta imot manuelle peilinger",
    "noTank": "Ingen tank",
    "noTanksToDisplay": "Ingen tanker å vise",
    "nr": "Tank",
    "nrOfTanks": "Antall tanker",
    "percentageCalculationInfo": {
      "maxFuel": "Prosent beregnet basert på tom tank (0L) og fyllingsgrad ({{maxFuelLevel}}L)",
      "minFuel": "Prosent beregnet basert på run-dry nivå ({{minFuelLevel}}L) og tankstørrelse ({{capacity}}L)",
      "minFuelAndMaxFuel": "Prosent beregnet basert på run-dry nivå ({{minFuelLevel}}L) og fyllingsgrad ({{maxFuelLevel}}L)",
      "noLimits": "Prosent beregnet basert på tom tank (0L) og tankstørrelse ({{capacity}}L)"
    },
    "probe": "Probe",
    "quality": "Kvalitet",
    "quantity": "Beholdning",
    "quantityMax": "Kan ikke overstige {{quantity}}L",
    "quantityMin": "Min.beholdning er {{quantity}}L",
    "readingType": {
      "0": "Automatisk",
      "1": "Manuell",
      "2": "Teoretisk",
      "3": "Skanol",
      "unknown": "Ukjent"
    },
    "recentManualReadingRequired": "Krever manuell peiling de siste 24 timene",
    "registerDipping": "Registrer peiling",
    "searchForTanks": "Søk etter tanker",
    "seeAllDippings": "Se alle peilinger",
    "seeDippings": "Se peilinger",
    "selectReadingTypes": "Velg peiletyper",
    "sendDipTo": "Send peiling til",
    "setTime": {
      "minusTenMinutes": "-10 minutter",
      "now": "Nå"
    },
    "sooloId": "SooloID",
    "tank": "Tank",
    "tankGroup": "Tankgruppe",
    "tankdetails": "Detaljer for tank",
    "tankid": "TankID",
    "tanknrNotUnique": "Tanknr. må være unikt for stasjonen",
    "tanks": "Tanker",
    "theoreticInventoryCalculation": "Teoretisk beregning",
    "theoretical": "Teoretisk",
    "totalVolume": "Totalvolum",
    "unitOfMeasure": {
      "gallon": "Gallon",
      "litre": "Liter"
    },
    "volume": "Volum"
  },
  "transactions": {
    "cardNr": "Kortnr.",
    "cardNumber": "Kortnummer",
    "endOfDay": "EOD",
    "fillingId": "FyllingsID",
    "fuelNozzleId": "PistolID",
    "fuelPumpId": "PumpeID",
    "fuelUnitPrice": "Kr/L",
    "generateReceiptPdf": "Generer PDF-kvittering",
    "generateReceiptPdfDisabled": "Kan ikke generere PDF-kvittering, grunnet manglende data.",
    "manual": "Manuelt",
    "manualTransaction": "Manuelt salg",
    "paymentMethod": "Korttype",
    "paymentProvider": "Sys",
    "pumpId": "PumpeID",
    "readMethod": "Lesemetode",
    "sales": "Salg",
    "sum": "Sum",
    "sumKr": "Sum kr",
    "sumVolume": "Sum liter",
    "terminalId": "TerminalID",
    "totalSum": "Totalsum"
  },
  "ui": {
    "adjustColumns": "Tilpass kolonner",
    "allColumns": "Alle kolonner",
    "changelog": "Endringslogg",
    "datePicker": {
      "endDate": "Sluttdato",
      "enterDate": "Velg dato",
      "enterDateRange": "Velg datoer",
      "goOneDayBack": "Gå en dag bakover",
      "goOneDayForward": "Gå en dag fremover",
      "goToToday": "Sett dagens dato",
      "invalidEndDate": "Ugyldig sluttdato",
      "invalidStartDate": "Ugyldig startdato",
      "startDate": "Startdato"
    },
    "filter": "Filter",
    "filterBy": "Filtrer på",
    "firstPage": "Første side",
    "itemsPerPage": "Antall per side",
    "landingInfo": "For å få tilgang til innholdet på siden må du logge inn.",
    "lastPage": "Siste side",
    "login": "Logg inn",
    "max": "Maks",
    "min": "Min",
    "mode": {
      "dark": "Mørk modus",
      "light": "Lys modus"
    },
    "nextPage": "Neste side",
    "notLoggedIn": "Ingen pålogget bruker",
    "numberOfHits": "Antall treff",
    "ofLabel": "av",
    "previousPage": "Forrige side",
    "selectPage": "Velg side",
    "showAll": "Vis alle",
    "showFewer": "Vis færre",
    "unreadChangelogs": "{{count}} uleste endringslogger",
    "welcome": "Hei"
  },
  "user": {
    "activate": "Aktiver bruker",
    "deactivate": "Deaktiver bruker",
    "deactivated": "Deaktiverte brukere",
    "delete": "Slett bruker",
    "deletesAllOtherRoles": "Merk: Sletter alle andre eksisterende roller",
    "details": "Detaljer for bruker",
    "edit": "Rediger bruker",
    "email": "Epost",
    "error": {
      "invalidEmail": "Ugyldig epost-adresse",
      "invalidEmailDomain": "Ugyldig epost-domene",
      "invalidPhone": "Ugyldig telefonnummer"
    },
    "firstName": "Fornavn",
    "globalUser": "Global bruker",
    "id": "BrukerId",
    "invite": "Inviter bruker",
    "lastName": "Etternavn",
    "messages": {
      "cannotDeleteUser": "Brukeren kunne ikke slettes.",
      "cannotRemoveRoles": "Roller kunne ikke fjernes.",
      "cannotSave": "Brukeren kunne ikke lagres.",
      "cannotSaveNewRoles": "Nye roller kunne ikke legges til.",
      "cannotSendInvite": "Invitasjonen kunne ikke sendes.",
      "inviteSent": "Invitasjonen er sendt.",
      "noAccessToEdit": "Du har ikke tilgang til å redigere denne rollen.",
      "rolesSaved": "Brukerroller oppdatert.",
      "userDeleted": "Brukeren har blitt slettet.",
      "vippsEmailHint": "Må matche e-postaddressen registrert i Vipps"
    },
    "name": "Navn",
    "new": "Ny bruker",
    "permanentlyDeleted": "Tilgangen til {{ user }} vil bli permanent slettet. Er du sikker?",
    "phone": "Telefon",
    "role": "Rolle",
    "roles": "Roller",
    "searchStation": "Søk etter stasjoner",
    "searchUser": "Søk etter bruker",
    "stations": "Stasjoner",
    "type": {
      "email": "Epost-bruker",
      "phone": "Vipps-bruker"
    },
    "unknown": "Ukjent bruker",
    "user": "Bruker",
    "users": "Brukere"
  },
  "userSettings": {
    "chooseNotifications": "Velg ønskede notifikasjoner",
    "displayRoutesActive": "Kjøreruter aktive",
    "messages": {
      "cannotGetSettings": "Kunne ikke hente brukerinnstillinger.",
      "cannotSaveSettings": "Kunne ikke lagre brukerinnstillinger. ",
      "settingsSaved": "Brukerinnstillinger lagret."
    },
    "noSettings": "Det finnes ingen varslingsinnstillinger å vise.",
    "notificationsEnabled": "Motta varsler",
    "userHasNoPushoverKey": "Brukeren din har ingen Pushover-nøkkel, og kan derfor ikke motta varsler.",
    "userSettings": "Brukerinnstillinger"
  },
  "washsettlement": {
    "amount": "Beløp",
    "cannotGetDeviations": "Kunne ikke hente feil for vaskeoppgjør",
    "cannotStartExport": "Kunne ikke sende filer til Visma.",
    "cardNumber": "Kortnummer",
    "completedat": "Vask gjennomført",
    "confirmExport": "Denne prossessen kjører med jevne mellomrom i bakgrunnen. Kjør manuelt kun hvis du vet at du venter på filer.",
    "customerid": "Kundenr",
    "deviationList": "Feilliste",
    "deviations": "Avvik",
    "exportAlreadyRunning": "Denne prossessen kjører allerede. Prøv igjen senere.",
    "exportStarted": "Sending av filer til Visma har startet",
    "ismanualwash": "Manuell vask",
    "isreplacementwash": "Kompensasjonsvask",
    "issubscription": "Abonnement/enkeltvask",
    "licensePlate": "Kjennemerke",
    "noSaleDeviations": "Fant ingen feil relatert til salg",
    "noVerificationDeviations": "Fant ingen feil relatert til salgsverifikasjoner",
    "noWashDeviations": "Fant ingen feil relatert til vask",
    "paymentStatus": {
      "0": "Ukjent",
      "1": "Betalt",
      "2": "Refundert"
    },
    "paymenttype": "Betalingstype",
    "providerTransactionId": "TransaksjonsID",
    "refundReason": {
      "-1": "-",
      "0": "Angrerett",
      "1": "Nedgradert abonnement",
      "2": "Kundeservice",
      "3": "Nedetid",
      "4": "Kansellert",
      "5": "Tidsavbrudd"
    },
    "refundstatus": "Refunderingsgrunn",
    "sale": "Salg",
    "saleDate": "Salgsdato",
    "searchcustomer": "Søk etter kundenr",
    "sendToVisma": "Send til Visma manuelt",
    "singlewash": "Enkeltvask",
    "station": "Lokasjon",
    "status": "Betalingsstatus",
    "subscription": "Abonnement",
    "verifications": "Salgsverifikasjoner",
    "wash": "Vask",
    "washprogram": "Vaskeprogram navn",
    "washprogramid": "Vaskeprogram ID"
  }
}