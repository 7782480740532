import { MenuOption } from '@models/menuOption/menuOption.class';
import { Component, signal, input, effect, computed, Signal, ViewChild, output, DestroyRef, AfterViewInit } from '@angular/core';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { SettlementService } from '@app/core/http/settlement.service';
import { RoleEnum } from '@app/models/common/roles.enum';
import OnboardedHelper from '@app/core/helpers/onboarded.helper';
import { TranslatePipe } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { filter } from 'rxjs';
import { ReconciliationStatus } from '@app/models/settlement/settlement.class';
import AccessHelper from '@app/core/helpers/access.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WashSettlementService } from '@app/core/http/washsettlement.service';

@Component({
  selector: 'app-layout-menu-section',
  templateUrl: './layout-menu-section.component.html',
  styleUrls: ['./layout-menu-section.component.scss'],
  imports: [NgClass, MatTreeModule, MatButtonModule, RouterLink, MatIconModule, MatBadgeModule, TranslatePipe],
  animations: [
    trigger('slideVertical', [
      state('*', style({ height: 0 })),
      state('show', style({ height: '*' })),
      transition('* => *', [animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
    ])
  ]
})
export class LayoutMenuSectionComponent implements AfterViewInit {
  @ViewChild('tree') tree!: MatTree<MenuOption>;
  menuOptions = input.required<MenuOption[]>();
  currentRole = input.required<RoleEnum>();
  closeMenuEvent = output();

  menuTitle = input<string>('');
  isHidden = input<boolean>(true);
  filteredMenuOptions: Signal<MenuOption[]> = computed(() =>
    this.onboardedHelper.filterMenu(this.menuOptions()).filter(x => this.checkHasRole(x.roleAccess))
  );
  settlementBadgeCount = signal<string | number>(0);
  washSettlementBadgeCount = signal<string | number>(0);
  hasChildMenu = computed(() => this.menuOptions().some(x => x.subOptions?.length));

  constructor(
    private settlementService: SettlementService,
    private washSettlementService: WashSettlementService,
    private onboardedHelper: OnboardedHelper,
    private router: Router,
    private destroyRef: DestroyRef
  ) {
    // Update the deviations count if the deviations option is available and the user has access to view deviations
    effect(() => {
      if (this.filteredMenuOptions().some(x => x.redirectUrl === "/deviations") && AccessHelper.checkViewAccess("deviations", this.currentRole())) {
        this.getSettlementDeviationsCount();
      }
      if (this.filteredMenuOptions().some(x => x.redirectUrl === "/washDeviation") && AccessHelper.checkViewAccess("washDeviation", this.currentRole())) {
        this.getWashSettlementDeviationsCount();
      }
    });
  }

  ngAfterViewInit() {
    // Check if any of the menuOptions have children, only then subscribe to the router events
    if (this.hasChildMenu()) {
      // Ensure that the current menu option is expanded if a sub option is the current route (particularly for refreshes)
      this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const currentUrl = this.router.url.split('?')[0]; // Remove query params from url
          const optionWithOpenSubOption = this.filteredMenuOptions().find(x => x.subOptions?.some(y => y.redirectUrl === currentUrl));
          if (optionWithOpenSubOption) this.tree.expand(optionWithOpenSubOption);
        });
    }
  }

  checkAnyOptionHasRole = (roles: RoleEnum[]) => this.filteredMenuOptions().some(x => x.roleAccess.some(y => roles.includes(y)));
  checkHasRole = (roles: RoleEnum[]) => roles.includes(this.currentRole());
  closeMenuEventEmit = () => this.closeMenuEvent.emit();
  isCurrentActiveMenuItem = (url: string) => window.location.pathname == url;
  nodeHasChild = (_: number, node: MenuOption) => node.subOptions && node.subOptions?.length > 0;
  childrenAccessor = (node: MenuOption) => node.subOptions ?? [];
  getSettlementDeviationsCount = () => {
    this.settlementService.getAllSettlementsByStatus(ReconciliationStatus.Failed).subscribe(res => {
      this.settlementBadgeCount.set(res.totalCount > 100 ? '!' : res.totalCount);
    });
  }
  getWashSettlementDeviationsCount = () => {
    this.washSettlementService.getDeviations().subscribe(res => {
      const totalCount = res.sales.length + res.washes.length + res.verifications.length;
      this.washSettlementBadgeCount.set(totalCount > 100 ? '!' : totalCount);
    });
  }
}
